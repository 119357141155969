<template lang="html">
  <div>
    <ul
      v-if="tabs.length > 1 || tabsType !== 'SimpleTabs'"
      :class="['tabs__header', headerClasses]"
      role="tablist"
    >
      <li
        v-for="(tab, index) in tabs"
        :key="tab.props.title"
        :class="[{ tab__selected: index == selectedIndex }, tabClasses]"
        role="tab"
        tabindex="0"
        @click="
          $emit('update:model-value', index);
          selectedIndex = index;
        "
        @keyup.enter="
          $emit('update:model-value', index);
          selectedIndex = index;
        "
      >
        <div v-if="tabsType === 'VerticalTabs'">
          <span
            class="tab__number hidden font-light tracking-normal md:block"
            >{{ index + 1 }}</span
          >
          <span class="tab__line mt-1/2 block"></span>
          <h5 v-if="tab.props.title">{{ tab.props.title }}</h5>
        </div>
        <span v-else-if="tab.props.title">{{ tab.props.title }}</span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      tabClasses: String,
      headerClasses: String,
      tabsType: String,
      modelvalue: Number,
      deepChildren: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:model-value'],
    setup(props) {
      const slots = useSlots();
      const children = props.deepChildren
        ? slots.default()
        : slots.default()[0].children;

      return {
        tabs: children,
      };
    },
    data() {
      return {
        selectedIndex: 0,
      };
    },
    mounted() {
      this.selectTab(0);
    },
    methods: {
      selectTab(i) {
        this.selectedIndex = i;

        // loop over all the tabs
        this.tabs.forEach((tab, index) => {
          tab.isActive = index === i;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ul.tabs__header {
    list-style: none;
  }

  ul.tabs__header > li {
    display: inline-block;
    cursor: pointer;
  }

  .tab {
    display: inline-block;
  }

  .tab__number {
    font-size: 48px;
    line-height: 48px;
  }
</style>
